import React, { useEffect, useState } from 'react'
import tw from 'twin.macro'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Cookies from 'js-cookie'

import { graphql } from 'gatsby'
import { formatPhoneNumber } from '../helpers'
import A from '../components/A'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBuilding,
  faChevronRight,
  faClipboard,
  faHome,
  faMapMarkerAlt,
  faMobileAlt,
  faPhone,
  faPhoneAlt,
} from '@fortawesome/free-solid-svg-icons'
import { ButtonLink } from '../components/Button'
import Container from '../components/Container'
import { Label, Radio, Select } from '../components/Form/FormControls'
import { H1 } from '../components/Headings'

const BranchLoader = () => {
  const loaders = Array(8).fill('')
  return loaders.map(e => <div tw="bg-gray-300 animate-pulse h-64"></div>)
}

const BranchCard = ({ data }) => {
  const types = data.types.split(',')

  return (
    <div tw="p-8 border">
      <div tw="flex items-center justify-between mb-4">
        <span tw="text-2xl font-bold">
          <FontAwesomeIcon icon={faMapMarkerAlt} tw="mr-2 text-yellow-600" />{' '}
          {data.title}
        </span>
        <div tw="inline text-gray-500">
          <FontAwesomeIcon
            icon={faHome}
            css={[
              tw`mr-2`,
              types.includes('residential') && tw`text-yellow-600`,
            ]}
          />
          <FontAwesomeIcon
            icon={faBuilding}
            css={[types.includes('commercial') && tw`text-yellow-600`]}
          />
        </div>
      </div>
      <div tw="md:(grid grid-cols-2 col-gap-4)">
        <div tw="mb-8">
          <p tw="mb-2">
            <strong>Branch Office</strong>
            <address>
              {data.branchMeta.branchOfficeAddress}
              <br />
              {data.branchMeta.branchOfficeCity},{' '}
              {data.branchMeta.branchOfficeState}{' '}
              {data.branchMeta.branchOfficeZip}
            </address>
          </p>
          <p>
            <FontAwesomeIcon icon={faMobileAlt} tw="text-gray-500" />{' '}
            <A href={`tel:${data.branchMeta.branchOfficePhone}`}>
              {formatPhoneNumber(data.branchMeta.branchOfficePhone)}
            </A>
          </p>
        </div>
        <div>
          <p tw="mb-2">
            <strong>Office Hours</strong>
            <br />
            {data.branchMeta.branchOfficeHours}
          </p>
          <p>
            <FontAwesomeIcon icon={faClipboard} tw="text-gray-500" />{' '}
            <A>Request a Quote</A>
          </p>
        </div>
      </div>
      <ButtonLink href={`/branches/${data.slug}`} large>
        View Details <FontAwesomeIcon icon={faChevronRight} tw="ml-8" />
      </ButtonLink>
    </div>
  )
}

const BranchPage = ({ data }) => {
  const [branchTypeRadioValue, setBranchTypeRadioValue] = useState('both')

  const branches = data.allWpBranch.nodes.map(branch => {
    branch.types = branch.branchTypes.nodes.map(node => node.slug).join(',')
    branch.state = branch.branchMeta.branchOfficeState
    return branch
  })

  const multiPropsFilter = (array, filters) => {
    const filterKeys = Object.keys(filters)

    return array.filter(item => {
      return filterKeys.every(key => {
        if (!filters[key].trim().length) return true

        return item[key] === filters[key]
      })
    })
  }

  const updateFilters = (key, value) => {
    const newFilters = Object.assign({}, filters, { [key]: value })
    setFilters(newFilters)
  }

  const [filteredBranches, setFilteredBranches] = useState()
  const [loading, setLoading] = useState(true)

  const [filters, setFilters] = useState({
    state: '',
    types: '',
  })

  useEffect(() => {
    setFilteredBranches(branches)
    setLoading(false)
  }, [])

  useEffect(() => {
    if (!filteredBranches) return

    setLoading(true)
    setFilteredBranches(multiPropsFilter(branches, filters))
    setLoading(false)
  }, [filters])

  return (
    <Layout>
      <SEO />
      <Container tw="py-8">
        <div tw="md:(grid grid-cols-4 col-gap-8)">
          <div tw="h-full">
            <div tw="sticky top-0">
              <H1 tw="text-2xl">Branch Locator</H1>
              <div
                tw="mb-6"
                onChange={e => {
                  setBranchTypeRadioValue(e.target.id)
                  updateFilters('types', e.target.value)
                }}
              >
                <Radio
                  name="residential"
                  group="res_com_filter"
                  label={
                    <>
                      <FontAwesomeIcon icon={faHome} /> Residential
                    </>
                  }
                  checked={branchTypeRadioValue === 'residential'}
                />
                <Radio
                  name="commercial"
                  group="res_com_filter"
                  label={
                    <>
                      <FontAwesomeIcon icon={faBuilding} /> Commercial
                    </>
                  }
                  checked={branchTypeRadioValue === 'commercial'}
                />
                <Radio
                  value=" "
                  name="both"
                  group="res_com_filter"
                  label="Both"
                  checked={branchTypeRadioValue === 'both'}
                />
              </div>
              <div>
                <Label for="state_select">Filter by State</Label>
                <Select
                  id="state_select"
                  onChange={e => updateFilters('state', e.target.value)}
                >
                  <option value="all">All States</option>
                  <option value="AL">Alabama</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="TN">Tennessee</option>
                </Select>
              </div>
            </div>
          </div>
          <div tw="md:(col-span-3 grid grid-cols-2 col-gap-8 row-gap-8)">
            {loading ? (
              <BranchLoader />
            ) : filteredBranches.length ? (
              filteredBranches.map(branch => <BranchCard data={branch} />)
            ) : (
              <div tw="col-span-2 w-full h-64 flex flex-col items-center justify-center">
                <div tw="text-4xl text-gray-900">No results found.</div>
                <p tw="text-gray-600">Please broaden your search terms.</p>
              </div>
            )}
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    allWpBranch(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        slug
        branchMeta {
          branchOfficeAddress
          branchOfficeCity
          branchOfficeHours
          branchOfficePhone
          branchOfficeState
          branchOfficeZip
        }
        branchTypes {
          nodes {
            slug
          }
        }
      }
    }
  }
`

export default BranchPage
